import a from '@/axios/auth';
import kpn from '@/axios/kpn';
import genesys from '@/axios/genesys';
import router from '@/router';
import AuthService from '@/services/AuthService.js';

export default {
  namespaced: true,
  state: {
    auth: null,
  },
  mutations: {
    SET_AUTH(state, auth) {
      state.auth = auth;
    },
  },
  actions: {
    updateAuth({ commit }, auth) {
      a.defaults.headers.common['Authorization'] = `Bearer ${auth.kpn.access_token}`;
      kpn.defaults.headers.common['Authorization'] = `Bearer ${auth.kpn.access_token}`;
      genesys.defaults.headers.common['Authorization'] = `Bearer ${auth.genesys.access_token}`;
      commit('SET_AUTH', auth);
    },
    logout() {
      router.push({ name: 'login' }).then(() => window.location.reload());
    },
    async getToken({ dispatch }, { code, redirectUri }) {
      const { data } = await AuthService.getAccessToken(code, redirectUri);
      dispatch('updateAuth', data);
    },
    async refreshToken({ dispatch, state }) {
      const { data } = await AuthService.refreshAccessToken(state.auth.genesys.refresh_token);
      dispatch('updateAuth', data);
      return data;
    },
  },
  getters: {
    loggedIn: (state) => !!state.auth,
  },
};
